const teamData = [
    {
        id: 3,
        name: "Бектур",
        responsibility: "Координатор",
        img: "bektur"
    },
    {
        id: 4,
        name: "Эльдана",
        responsibility: "Координатор",
        img: "eldana"
    },
    {
        id: 5,
        name: "Саидислом",
        responsibility: "Sumo",
        img: "saidislom"
    },
    {
        id: 6,
        name: "Магомед",
        responsibility: "Sumo",
        img: "magomed"
    },
    {
        id: 7,
        name: "Абай",
        responsibility: "Line follower",
        img: "abai"
    },
    {
        id: 8,
        name: "Нурсезим",
        responsibility: "Line follower",
        img: "nursezim"
    },
    {
        id: 9,
        name: "Баястан",
        responsibility: "Кегель ринг",
        img: "bayastan"
    },
    {
        id: 10,
        name: "Азалия",
        responsibility: "Кок Бору",
        img: "azalia"
    },
    {
        id: 12,
        name: "Айбийке",
        responsibility: "Дроны",
        img: "aibiike"
    },
    {
        id: 13,
        name: "Гульзада",
        responsibility: "Дроны",
        img: "gulzada"
    },
    {
        id: 14,
        name: "Эрлан",
        responsibility: "Миникубок Голдберга",
        img: "erlan"
    },
    {
        id: 15,
        name: "Шахназа",
        responsibility: "Гонка",
        img: "shahnaza"
    },
    {
        id: 16,
        name: "Бермет",
        responsibility: "Гонка",
        img: "bermet"
    },
    {
        id: 17,
        name: "Байсал",
        responsibility: "Mailo",
        img: "baisal"
    },
    {
        id: 18,
        name: "Азис",
        responsibility: "Mailo",
        img: "azis"
    },
    {
        id: 19,
        name: "Санжар",
        responsibility: "Миникубок Голдберга",
        img: "sanjar"
    },
    {
        id: 20,
        name: "Нурсултан",
        responsibility: "Вездеход",
        img: "nursultan"
    },
    {
        id: 21,
        name: "Тынай",
        responsibility: "Перетягивание каната",
        img: "tynai"
    },
    {
        id: 22,
        name: "Санжар М",
        responsibility: "Перетягивание каната",
        img: "sanjar m"
    },
    {
        id: 23,
        name: "Азирет",
        responsibility: "Карантинная зона",
        img: "aziret"
    },
    {
        id: 24,
        name: "Эмир",
        responsibility: "Разработчик",
        img: "emir"
    },
]
export default teamData;